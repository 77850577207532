<template>
  <v-main class="full-layout ma-0">
    <v-navigation-drawer
      enable-resize-watcher
      class="nav-drawer primary"
      clipped
      app
      width="200"
    >
      <v-list dense nav>
        <v-list-item
          v-for="item in navigators"
          :key="item.title"
          :exact="item.exact"
          active-class="active-class"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon style="color: rgba(255, 255, 255, 0.7)">{{
              item.icon
            }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!---USer Area -->
      <v-list-item two-line class="profile-bg user-profile">
        <v-list-item-content class="white--text">
          <v-list-item-title>{{ authData.fullName }}</v-list-item-title>
          <v-list-item-subtitle class="caption white--text">{{
            userType
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!---USer Area -->
      <v-menu
        top
        class="mb-10"
        :close-on-content-click="false"
        transition="slide-y-transition"
        absolute
        internal-activator
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            bottom
            dark
            fab
            fixed
            right
            class="btn-logout indigo darken-4"
            small
          >
            <v-icon small> mdi-cog </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-group no-action active-class>
            <template v-slot:activator>
              <v-list-item class="pl-0">
                <v-list-item-action>
                  <v-icon>mdi-briefcase-account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("main.language")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item @click="changeLangToVi()">
              <v-list-item-content>
                <v-list-item-title>Tiếng việt</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="changeLangToEn()">
              <v-list-item-content>
                <v-list-item-title>English</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("main.logOut") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-navigation-drawer>
    <v-container fluid class="page-wrapper mt-0 pt-0">
      <router-view class="view" />
    </v-container>
  </v-main>
</template>

<script>
import { getNameInnitial } from "@/plugins/helper";
import { UserTypeCodes } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

const navigations = [
  {
    title: i18n.t("main.tab_left.tab_workList"),
    to: "/home/work-list/1",
    icon: "mdi-home-outline",
    exact: false,
    disabledUserTypes: [UserTypeCodes.Receptionist],
  },
  {
    title: i18n.t("main.tab_left.tab_doctorRoster"),
    to: "/home/roster",
    icon: "mdi-calendar-clock-outline",
    exact: true,
    disabledUserTypes: [
      UserTypeCodes.Root,
      UserTypeCodes.Admin,
      UserTypeCodes.Doctor,
      UserTypeCodes.Optomologist,
    ],
  },
  {
    title: i18n.t("main.tab_left.tab_calendar"),
    to: "/home/calendar",
    icon: "mdi-calendar-outline",
    exact: true,
    disabledUserTypes: [UserTypeCodes.Nurse, UserTypeCodes.Receptionist],
  },
  {
    title: i18n.t("main.tab_left.tab_patient"),
    to: "/home/patient",
    icon: "mdi-account-outline",
    exact: true,
    disabledUserTypes: [],
  },
  {
    title: i18n.t("main.tab_left.tab_report"),
    to: "/home/report/finish",
    icon: "mdi-chart-line",
    exact: true,
    disabledUserTypes: [],
  },
  {
    title: i18n.t("main.tab_left.tab_medicine"),
    to: "/home/medicine",
    icon: "mdi-plus-circle-outline",
    exact: false,
    disabledUserTypes: [
      UserTypeCodes.Doctor,
      UserTypeCodes.Optomologist,
      UserTypeCodes.Receptionist,
      UserTypeCodes.Nurse,
      UserTypeCodes.Technician,
    ],
  },
  {
    title: i18n.t("main.tab_left.tab_form"),
    to: "/home/form",
    icon: "mdi-file-outline",
    exact: false,
    disabledUserTypes: [
      UserTypeCodes.Doctor,
      UserTypeCodes.Optomologist,
      UserTypeCodes.Receptionist,
      UserTypeCodes.Nurse,
      UserTypeCodes.Technician,
    ],
  },
  {
    title: i18n.t("analysis.Analysis"),
    to: "/home/analysis",
    icon: "mdi-image-outline",
    exact: false,
    disabledUserTypes: [
      UserTypeCodes.Optomologist,
      UserTypeCodes.Receptionist,
      UserTypeCodes.Nurse,
    ],
  },
  {
    title: i18n.t("flow.Flow"),
    to: "/home/flow",
    icon: "mdi-chart-box-outline",
    exact: false,
    disabledUserTypes: [
      UserTypeCodes.Optomologist,
      UserTypeCodes.Receptionist,
      UserTypeCodes.Nurse,
      UserTypeCodes.Doctor,
    ],
  },
];
export default {
  name: "HomeLayout",
  props: {
    source: String,
  },
  watch: {
    // When navigate between home tabs
    // Update current url into cache
    $route(value) {
      this.$store.commit("UPDATE_HEADER_TAB_HOME", value.path);
    },
  },
  computed: {
    authData() {
      return this.$store.getters.userData || {};
    },
    navigators() {
      var userType =
        this.$store.getters.userData && this.$store.getters.userData.userTypeID;
      return navigations.filter(
        (nav) => !nav.disabledUserTypes.includes(userType)
      );
    },
  },
  created() {
    if (this.$route.path != this.navigators[0].to) {
      this.$router.push(this.navigators[0].to);
    }
  },
  data: () => ({
    userType: "",
  }),
  mounted() {
    this.convertLangCurrentUserType();
  },
  methods: {
    getNameInnitial,
    logout() {
      if (parent && typeof parent.postMessage == "function") {
        return parent.postMessage({ event: "LOGOUT" }, "*");
      }
      this.$store.commit("CLEAR_ACCESS_TOKEN");
      this.$store.commit("CLEAR_USER_DATA");
      this.$router.push({ path: "/login" });
    },
    changeLangToVi() {
      localStorage.setItem("LOCATE_LANGUAGE", "vn");
      i18n.locale = "vn"; // important!
      location.reload();
    },
    changeLangToEn() {
      localStorage.setItem("LOCATE_LANGUAGE", "en");
      i18n.locale = "en"; // important!
      location.reload();
    },
    convertLangCurrentUserType() {
      switch (this.authData.userTypeName) {
        case "Admin":
          this.userType = i18n.t("main.userType.admin");
          break;
        case "Doctor":
          this.userType = i18n.t("main.userType.doctor");
          break;
        case "Root":
          this.userType = i18n.t("main.userType.root");
          break;
        case "Optomologist":
          this.userType = i18n.t("main.userType.optomologist");
          break;
        case "Receptionist":
          this.userType = i18n.t("main.userType.receptionist");
          break;
        case "Nurse":
          this.userType = i18n.t("main.userType.nurse");
          break;
        case "Technician":
          this.userType = i18n.t("main.userType.technician");
      }
    },
  },
};
</script>

<style lang="scss">
.full-layout {
  background-color: white !important;

  .nav-drawer {
    .user-profile {
      position: fixed;
      bottom: 0px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btn-logout {
      bottom: 60px;
    }
    .v-list--nav {
      padding: 0;
      .v-list-item {
        border-radius: 0;
      }
    }
    .v-list-item__title {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .active-class {
      background-color: #0065ff;
      color: #ffffff;
    }
  }
  .v-menu__content {
    top: auto !important;
    bottom: 108px;
  }
}
</style>
